.menu-links-wrap {
  overflow: auto;
}

.menu-links {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
  margin: 1rem 0;
}

.menu-links-item,
.menu-links-active {
  display: block;
  padding: 0.5rem;
  font: inherit;
  text-decoration: none;
  font-size: 1rem;
  border: 1px solid #000000;
  border-radius: 0.5rem;
  white-space: nowrap;
  overflow-y:visible
}

.menu-links-item {
  color: #000000;
  background: none;
}

.menu-links-active {
  color: #ffffff;
  background: #000000;
}
