.menu-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.menu-item-photo {
  width: 100%;
}

.menu-item-photo img {
  width: 100%;
}

.menu-item-info {
  width: 100%;
  padding: 1rem 0;
}

.menu-item-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
}

.menu-item-title p {
  min-width: 20%;
  font-size: 1.2rem;
  font-weight: 500;
}

.menu-item-price {
  text-align: right;
}

.menu-item-description {
  margin-top: .5rem;
  font-size: 1rem;
  opacity: 0.5;
}
