@import url("./assets/fonts/Montserrat/stylesheet.css");

* {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Montserrat";
  font-size: clamp(10px, 0.833333vw, 18px);
}

main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.content {
  width: 80rem;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 3vw;
  }

  .content {
    width: 90vw;
  }
}
