.menu-group-wrap {
  position: relative;
  padding-bottom: 2rem;
}

.menu-group-anchor {
  position: absolute;
  top: -17rem;
  z-index: -1;
}

.menu-group-wrap:first-child {
  padding-top: 17rem;
}

.menu-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.menu-group-title {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 500;
}

@media screen and (max-width: 1200px) {
  .menu-group {
    grid-template-columns: repeat(2, 1fr);
  }

  .menu-group-anchor {
    top: -19rem;
  }
  
  .menu-group-wrap:first-child {
    padding-top: 19rem;
  }
}

@media screen and (max-width: 760px) {
  .menu-group {
    grid-template-columns: 1fr;
  }
}